<!-- 充值活动弹窗 -->
<template>
    <img class="bg" v-if="bg" :src="bg | compress(650, true)" @click="toChargeActivity" />
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'ChargeActivityPopup',
    computed: {
        ...mapState('activity', ['entryConfig', 'rechargeConfig']),
        bg() {
            return this.entryConfig.popImg;
        },
    },
    mounted() {
        this.sa('visit_Popup', {
            cps_parameter: this.rechargeConfig.cps,
            popupProperties: '立即充值',
            popupName: '充值活动弹窗',
            TriggerPage: this.$route.meta.trackName,
        });
    },
    methods: {
        toChargeActivity() {
            this.sa('CommonBtnClk', {
                cps_parameter: this.rechargeConfig.cps,
                uid: this.$store.state.userInfo.uid,
                CurPage: this.$route.meta.trackName,
                ButtonName: `点击充值(${this.$route.meta.trackName})`,
            });

            this.$router.push({
                name: 'ChargeActivity',
                params: {
                    from: `弹窗(${this.$route.meta.trackName})`,
                },
            });
            this.$parent.close();
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';

.bg {
    width: .convert(653px) [ @vw];
}
</style>
