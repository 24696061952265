<template>
    <div class="page-charge">
        <div @click="$parent.close()" class="close-btn"></div>
        <transition-group name="fade-in">
            <h3 class="charge-title" key="charge-title">支持作者，充值后继续阅读</h3>
            <ChargeContent
                key="charge-content"
                class="charge-content"
                v-if="gotData"
                :chargeList="chargeList"
                :vipRechargeList="vipRechargeList"
                :curChargeTarget="curChargeTarget"
                :discountRechargeGoods="discountRechargeGoods"
                :PrePage="popFrom"
                :payTypes="payTypes"
                :topicPrice="topicPrice"
                @choose="onChoose"
                @changePayType="changePayType"
                @doCharge="doCharge"
            />
        </transition-group>
    </div>
</template>
<script>
import ChargeContent from '@/components/Charge/ChargeContent.vue';
import { mapState } from 'vuex';
import payController from '@/components/Charge/Controller.js';
import { ChargeUtils } from '@/modules/charge.js';
import rechargeSelectMixin from '@/mixin/rechargeSelectMixin';

export default {
    name: 'Charge',
    mixins: [rechargeSelectMixin],
    components: {
        ChargeContent,
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        comicId: [String, Number],
        // eslint-disable-next-line vue/require-default-prop
        topicId: [String, Number],
        topicPrice: {
            type: Number,
            default: 0,
        },
    },
    data: function () {
        return {
            gotData: false,
            popFrom: '漫画阅读页', // 弹出弹窗来源页面
        };
    },
    watch: {
        '$route.name'(newVal) {
            // 由漫画阅读点击系统返回键到(首页 或者 热门推荐) 关闭付费弹窗并屏蔽动画
            const closePayPopupRouterList = ['Index', 'RecentRead', 'HotList'];
            if (closePayPopupRouterList.indexOf(newVal) > -1) {
                this.closeNoAnimation();
            }
        },
    },
    computed: {
        ...mapState(['staticInfo', 'accountBalance', 'userInfo']),
    },
    methods: {
        closeNoAnimation() {
            const popupIns = this.$parent;
            popupIns.willLeave = true;
            popupIns.leaved = true;
            popupIns.$emit('afterLeave', {});
        },
        async doCharge() {
            if (!this.userInfo.isLogin) {
                this.$router.push({
                    path: '/login',
                });
                return;
            }
            const { totalChargeCnt, lastChargeTime } = payController.payDate;
            this.sa('ClickRechargeButton', {
                PaymentsAccount: this.curChargeTarget.real_price,
                LastRechargeTime: lastChargeTime,
                RechargeType: totalChargeCnt,
                LatestBalance: this.accountBalance,
            });
            const num = (this.curChargeTarget.real_price / 100).toFixed(2);
            this.sa('CommonBtnClk', {
                uid: this.userInfo.uid,
                CurPage: this.popFrom,
                ButtonName: `立即充值：${num}元`,
            });
            const chargeUtils = new ChargeUtils({
                comicId: this.comicId, //int 漫画id
                topicId: this.topicId, //int 专题id
                goodId: this.curChargeTarget.id,
                payType: this.activePayType,
                goodType: this.curChargeTarget.good_type,
                goodPrice: this.curChargeTarget.price | this.curChargeTarget.real_price,
            });
            this.confirmCharge()
                .then(async () => {
                    try {
                        await chargeUtils.charge();
                    } catch (error) {
                        this.$toast.show('充值失败，请稍后再试～');
                        throw error;
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    },
    async created() {
        this.removeBodyScroll();
        document.title = '充值中心';
        loading.show();
        this.gotData = false;
        await this.getChargeInfo();
        this.gotData = true;
        await payController.getUserPayInfo();
        const { totalChargeCnt, lastChargeTime } = payController.payDate;
        this.sa('VisitCheckout', {
            LastRechargeTime: lastChargeTime,
            RechargeType: totalChargeCnt,
            LatestBalance: this.accountBalance,
        });
    },
    mounted() {
        this.sa('visit_Popup', {
            uid: this.userInfo.uid,
            TriggerPage: '漫画阅读页',
            popupName: '漫画阅读页充值弹窗',
        });
    },
    beforeDestroy() {
        this.addBodyScroll();
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';

/* 余额区域 */
.charge-title {
    display: block;
    .vws(width, 750px);

    font-size: 16px;
    font-family: 'PingFang SC';
    font-weight: 500;
    text-align: center;
    line-height: 22px;
    .vws(margin-top, 42px);
    .vws(margin-bottom, 8px);
}
.page-charge {
    position: relative;
    height: 74vh;
    .vws(width, 750px);

    border-radius: 10px 10px 0 0;
    text-align: left;
    background: #fff;
}
.close-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    .vws(width, 102px);
    .vws(height, 82.5px);

    background-position: center;
    background-repeat: no-repeat;
    .vws(background-size-x, 32px);
    .vws(background-size-y, 32px);

    background-image: url('./btn_off_directory.svg');
}

/* 加载数据时的动画效果 */
.fade-in-enter-active {
    transition: opacity 0.3s ease;
}
.fade-in-enter {
    opacity: 0;
}

.charge-content::v-deep {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    height: calc(100% - 72px - 9.3%);
    height: calc(100% - 72px - 9.3% - constant(safe-area-inset-bottom));
    height: calc(100% - 72px - 9.3% - env(safe-area-inset-bottom));

    /* 余额区域 */
    .mod-balance {
        height: 74px;
        color: #333;
        .balance-num {
            color: #ff8739;
        }
    }

    .discount-charge {
        .vws(margin-top, 40px);
        .vws(margin-bottom, 10px);
    }
    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
