<!-- 目录组件 -->
<template>
    <div ref="catalogListWrap" class="catalog-list-wrap">
        <!-- 列表头 S -->
        <div class="list-head" @click="$parent.close()">
            <div class="update-info">
                {{ categoryDate.updateInfo.update_status || '' }}
            </div>
            <div class="order-info">
                <span
                    :class="categoryDate.orderType === 'sequent' ? 'active' : ''"
                    @click.stop="changeOrder('sequent')"
                >
                    正序
                </span>
                <span
                    :class="categoryDate.orderType === 'reverse' ? 'active' : ''"
                    @click.stop="changeOrder('reverse')"
                >
                    倒序
                </span>
            </div>
        </div>
        <!-- 列表头 E -->

        <!-- 章节列表 S -->
        <div ref="chapterList" class="chapter-list">
            <div class="price-wrap">
                <i class="price-icon"></i>
                <span class="price-text android-hack">
                    漫画单章定价{{ categoryDate.topic_price }}书币
                </span>
            </div>
            <div
                v-for="(item, index) in displayComics"
                :key="index"
                :data-index="index"
                class="chapter-item"
                :class="{ 'to-relative': showingComicId == item.id }"
                @click="clickItem(item.id)"
            >
                <div class="cover-box">
                    <div class="cover">
                        <img
                            v-lazyLoad
                            :data-src="item.cover_image_url"
                            :key="item.cover_image_url"
                            alt=""
                        />
                        <div v-show="item.locked" class="locked">
                            <i class="lock-icon"></i>
                        </div>
                        <div v-if="!item.is_free && !item.locked" class="bottom-tips">
                            <i class="unlock-icon"></i>
                            <span class="limit_free-tips" v-if="item.is_limited_free">限免</span>
                        </div>
                        <ComicLabel v-if="showLabel && !item.is_free" />
                    </div>
                </div>

                <div class="right">
                    <h3 class="title">
                        {{ item.title }}
                    </h3>
                    <p>{{ item.created_at }}</p>
                </div>
                <div v-if="showingComicId == item.id" class="reading-icon"></div>
            </div>
        </div>
        <!-- 章节列表 E -->
    </div>
</template>
<!-- 专题详情页目录半屏弹窗 E -->

<script>
import Controller from './Controller.js';
import ComicLabel from '@/components/label/index.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'Category',
    // eslint-disable-next-line vue/require-prop-types
    props: ['updateComic'],
    components: {
        ComicLabel,
    },
    computed: {
        ...mapGetters(['canFeelVip']),
        showLabel() {
            const { vip_free: vipFree } = this.categoryDate.updateInfo;
            return this.canFeelVip && vipFree;
        },
        displayComics() {
            if (this.categoryDate.orderType === 'sequent') {
                return this.categoryDate.comics;
            }
            return this.categoryDate.comics.map((comic) => comic).reverse();
        },
        categoryDate() {
            return Controller.categoryDate;
        },
        routerName() {
            return this.$route.name;
        },
        showingComicId() {
            return this.$store.state.showingComicId;
        },
    },
    watch: {
        routerName() {
            // 目录开启时 点击系统back键 隐藏目录半屏
            this.closeNoAnimation();
        },
    },
    methods: {
        closeNoAnimation() {
            const popupIns = this.$parent;
            popupIns.willLeave = true;
            popupIns.leaved = true;
            popupIns.$emit('afterLeave', {});
        },
        clickClose() {
            this.$parent.close();
        },
        //点击排序按钮触发
        changeOrder(orderType) {
            const chapterList = this.$refs.chapterList; // 目录列表盒子
            if (chapterList) {
                chapterList.scrollTop = 0;
            }
            this.categoryDate.orderType = orderType;
        },
        clickItem(id) {
            if (id == this.showingComicId) {
                return;
            }
            // 点击目录 刷新当前页面所有漫画信息
            this.$parent.close();
            loading.show();
            this.updateComic(id);
        },
    },
    created() {
        this.removeBodyScroll();
    },
    beforeDestroy() {
        this.addBodyScroll();
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';

.catalog-list-wrap {
    position: relative;
    z-index: 1;
    display: flex;
    margin: 0 auto;
    border-radius: 6px 6px 0 0;
    background: #fff;
    flex-direction: column;
    .vws(width, 750px);
    .vws(height, 928px);
}

//标题
.list-head {
    .vws(padding-top, 42px);
    .vws(padding-left, 32px);
    .vws(padding-right, 32px);
    .vws(padding-bottom, 24px);

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    .vws(height, 106px);

    .update-info {
        .vws(max-width, 480px);
        .vws(height, 40px);
        .vws(line-height, 40px);
        .vws(font-size, 32px);

        overflow: hidden;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: rgba(51, 51, 51, 1);
    }

    .order-info {
        .vws(margin-left, 32px);
        .vws(padding-top, 6px);
        .vws(padding-left, 6px);
        .vws(padding-right, 6px);
        .vws(padding-bottom, 6px);
        .vws(height, 60px);
        .vws(width, 172px);

        font-size: 0;
        text-align: center;
        color: #999;
        background-color: #f7f9fa;
        box-sizing: border-box;
        .vws(border-radius, 28px);

        span {
            display: inline-block;
            .vws(height, 48px);
            .vws(line-height, 48px);
            .vws(width, 80px);
            .vws(border-radius, 24px);
            .vws(font-size, 22px);

            &.active {
                font-weight: 500;
                color: #333;
                background: #fff;
            }
        }
    }
}

// 定价
.price-wrap {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    .vws(height, 48px);
    .vws(margin-left, 32px);
    .vws(margin-right, 32px);
    .vws(padding-left, 14px);
    .vws(border-radius, 12px);

    background: #f7f7f8;

    i {
        display: block;
        .vws(height, 28px);
        .vws(width, 28px);
        .vws(margin-right, 8px);

        background-size: 100% 100%;
    }

    .price-icon {
        background-image: url('./price.png');
    }

    .price-text {
        color: #333;
        .vws(font-size, 22px);
    }
    .lable-border {
        border-radius: 9px;
    }
}

//列表
.chapter-list {
    overflow: auto;
    width: 100%;
    // TODO ios及低版本android 兼容
    overscroll-behavior: contain;

    .chapter-item {
        .vws(padding-top, 24px);
        .vws(padding-left, 32px);
        .vws(padding-right, 32px);
        .vws(padding-bottom, 24px);

        display: flex;
        align-items: center;
        .vws(height, 144px);

        box-sizing: border-box;

        .cover-box {
            .vws(width, 190px);
            .vws(height, 116px);

            background: #f8f7f7;
            flex-shrink: 0;
        }

        .cover {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;
            .vws(border-radius, 8px);
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            .locked {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                font-size: 0.6rem;
                color: rgba(252, 233, 79, 1);
                background-color: rgba(0, 0, 0, 0.6);

                i {
                    display: inline-block;
                    .vws(height, 38px);
                    .vws(width, 38px);

                    background-size: 100% 100%;
                }

                .lock-icon {
                    background-image: url('./lock.png');
                }
            }

            .bottom-tips {
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                padding-left: 2px;
                width: 100%;
                height: 5vw;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-image: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.6) 100%
                );
                box-sizing: border-box;
                .unlock-icon {
                    display: inline-block;
                    .vws(height, 24px);
                    .vws(width, 26px);

                    background-size: 100% 100%;
                    background-image: url('./unlock.png');
                }
                .limit_free-tips {
                    margin-left: 5px;
                    font-size: 11px;
                    color: #fff;
                    line-height: 11px;
                }
            }
        }

        .right {
            .vws(padding-left, 24px);

            overflow: hidden;
            text-align: left;
            h3.title {
                overflow: hidden;
                margin: 0;
                font-weight: 400;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: rgba(51, 51, 51, 1);
                .vws(height, 40px);
                .vws(line-height, 40px);
                .vws(font-size, 32px);
            }

            p {
                .vws(margin-top, 18px);
                .vws(height, 30px);
                .vws(line-height, 30px);
                .vws(font-size, 24px);

                font-weight: 400;
                color: rgba(153, 153, 153, 1);
            }
        }
        .reading-icon {
            position: absolute;
            right: 0;
            background: url('./ic_album_chapter_marker@2x.png') no-repeat;
            background-size: 100% 100%;
            .vws(width, 50px);
            .vws(height, 44px);
            .vws(top, 34px);
        }
    }
}
.to-relative {
    position: relative;
}

@media screen and (min-width: 750px) {
    .catalog-list-wrap {
        height: 60vh;
    }
}
</style>
