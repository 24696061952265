<template>
    <div class="recommend-wrap">
        <div class="header">
            爆款漫画推荐
            <div @click.passive="closePopup()" class="close-icon"></div>
        </div>
        <div class="scroll-wrap">
            <div
                @click="clickItem(comic)"
                class="comic-item"
                v-for="comic in hotComicList"
                :key="comic.comicTopicId"
            >
                <div class="comic-title-wrap">
                    <div class="comic-title">{{ comic.topicAliasOrName }}</div>
                    <div class="all-chapters">全集</div>
                </div>
                <div v-if="comic.labels && comic.labels.length > 0" class="comic-lables">
                    <div class="comic-lable" v-for="label in comic.labels" :key="label">
                        {{ label }}
                    </div>
                </div>
                <div class="comic-img">
                    <img v-lazyLoad :data-src="comic.topicImgUrl | compress(750)" alt="" />
                </div>
                <div v-if="comic.continueChapter" class="comic-record">
                    {{ comic.continueChapter.chapterName }}
                </div>
            </div>
        </div>
        <div class="to-index-btn" @click="goIndex">
            <span class="inside-btn">去书城首页</span>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    props: {
        count: {
            type: Number,
            default: 1,
        },
        update: {
            type: Function,
            default() {
                return {};
            },
        },
        beforeChange: {
            type: Function,
            default() {
                return {};
            },
        },
    },
    computed: {
        ...mapState(['hotComicList', 'showingComicId', 'userInfo']),
    },
    methods: {
        goIndex() {
            this.sa('CommonBtnClk', {
                ButtonName: '去书城首页_挽留弹窗',
                TriggerPage: '漫画详情页',
            });
            this.$parent.close();
            this.$on('hook:beforeDestroy', () => {
                this.$router.push({
                    name: 'Index',
                });
            });
        },
        closePopup() {
            this.$parent.close();
        },
        async clickItem(comic) {
            const id = comic.continueChapter && comic.continueChapter.chapterId;
            if (id == this.showingComicId) {
                this.$parent.close();
                return;
            }
            loading.show();
            this.$parent.close();
            this.update({ comicId: id, topicId: comic.comicTopicId });
        },
    },
    created() {
        localStorage.setItem(`${this.userInfo.userId}:new-user-count`, this.count + 1);
        this.sa('visit_Popup', {
            TriggerPage: '漫画详情页',
            popupName: '挽留推荐弹窗',
        });
        this.removeBodyScroll();
    },
    beforeDestroy() {
        this.addBodyScroll();
    },
};
</script>
<style scoped>
.recommend-wrap {
    display: flex;
    overflow: hidden;
    width: 100vw;
    height: 75vh;
    border-radius: 10px 10px 0 0;
    background: #f7f9fa;
    flex-direction: column;
}
.header {
    position: relative;
    height: 53px;
    font-size: 16px;
    font-family: PingFangSC-Medium, 'PingFang SC';
    font-weight: 500;
    color: #333;
    background: #fff;
    line-height: 53px;
    flex-shrink: 0;
}
.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 53px;
    height: 53px;
    background: url('./btn_off_directory@2x.png') center/16px no-repeat;
}
.scroll-wrap {
    overflow-y: scroll;
    padding-bottom: 80px;
    padding-bottom: calc(80px + constant(safe-area-inset-bottom));
    padding-bottom: calc(80px + env(safe-area-inset-bottom));
}
.comic-item {
    overflow: hidden;
    margin-bottom: 6px;
    padding: 0 12px;
    background: #fff;
}
.comic-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 13px;
}
.comic-title {
    overflow: hidden;
    margin-right: 10vw;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333;
    line-height: 18px;
}
.all-chapters {
    font-size: 13px;
    color: #999;
    line-height: 18px;
    flex-shrink: 0;
}
.all-chapters::after {
    display: inline-block;
    margin-left: 6px;
    width: 6px;
    height: 10px;
    background: url('./icon_arrow.png') center/100%;
    content: '';
}
.comic-lables {
    display: flex;
    align-items: center;
    margin-top: 9px;
}
.comic-lable {
    overflow: hidden;
    margin-right: 9px;
    padding: 5px 8px;
    font-size: 11px;
    border-radius: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #b89668;
    background: #f9f3e8;
    line-height: 13px;
    word-wrap: normal;
}
.comic-img {
    margin-top: 10px;
    width: 100%;
    height: 52.6vw;
}
.comic-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.comic-record {
    overflow: hidden;
    margin: 16px 0;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #999;
    line-height: 16px;
}

.to-index-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-bottom: calc(20px + constant(safe-area-inset-bottom));
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
    width: 100%;
    background: #fff;
}
.to-index-btn .inside-btn {
    display: block;
    margin: 0 auto;
    width: 91.47%;
    height: 40px;
    font-size: 16px;
    border-radius: 20px;
    color: #fff;
    background: #f44;
    box-shadow: 0 3px 6px 0 rgba(255, 20, 61, 0.23);
    line-height: 40px;
}
</style>
