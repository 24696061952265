<template>
    <div class="teenager-popup">
        <img class="popup_img" src="./img/icon_top.jpg" alt="" />
        <p class="popup_title">青少年模式</p>
        <p class="popup_content">
            为呵护未成年人健康成长，漫画特别推出青少年模式，该模式下部分功能无法正常使用。请监护人主动选择。
        </p>
        <div class="popup_btn-confirm" @click="confirmHandler">
            进入青少年模式
            <img class="popup_icon-back" src="./img/icon_back.png" alt="" />
        </div>
        <div class="popup_btn-cancel" @click="cancelHandler">我知道了</div>
    </div>
</template>

<script>
export default {
    props: {
        storageKey: {
            type: String,
            default: '',
        },
        closeCallback: {
            type: Function,
            default: () => {
                // noop
            },
        },
    },
    methods: {
        confirmHandler() {
            this.$toast.show('已开启青少年模式');
            this.closeCallback(true);
        },
        cancelHandler() {
            this.closeCallback(false);
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';

.teenager-popup {
    .vws(padding-top, 24px);
    .vws(padding-right, 32px);
    .vws(padding-bottom, 48px);
    .vws(padding-left, 32px);

    .vws(width, 576px);
    .vws(border-radius, 24px);

    text-align: center;
    background: #fff;
    .popup_img {
        .vws(width, 176px);
        .vws(height, 176px);
    }
    .popup_title {
        margin-top: 6px;
        font-size: 18px;
        font-weight: 500;
        color: #333;
    }
    .popup_content {
        margin-top: 10px;
        font-size: 14px;
        text-align: left;
        color: #999;
    }
    .popup_btn-confirm {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
        color: #f46d6c;
        .popup_icon-back {
            width: 24px;
            height: 24px;
        }
    }
    .popup_btn-cancel {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 12px auto 0;
        height: 44px;
        font-size: 16px;
        font-weight: 500;
        border-radius: 22px;
        color: #fff;
        background: #ff4443;
        .vws(width, 510px);
    }
}
</style>
