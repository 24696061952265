<template>
    <div class="confirm" data-cy="confirm-component">
        <div class="confirm__title">{{ title }}</div>
        <div class="confirm__message" v-html="message"></div>
        <div class="confirm__icon" v-if="iconImage">
            <img :src="iconImage" />
        </div>
        <div class="btns">
            <button v-if="showCancle" class="btn btn-cancel" @click="actionFn('cancel')">
                {{ cancelBtn }}
            </button>
            <button v-if="showConfirm" class="btn btn-confirm" @click="actionFn('confirm')">
                {{ confirmBtn }}
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Confirm',
    props: {
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        confirmBtn: {
            type: String,
            default: '确定',
        },
        cancelBtn: {
            type: String,
            default: '取消',
        },
        actionFn: {
            type: Function,
            default: () => {
                // noop
            },
        },
        iconImage: {
            type: String,
            default: '',
        },
        showCancle: {
            type: Boolean,
            default: true,
        },
        showConfirm: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';
.confirm {
    .vws(width, 576px);

    border-radius: 6px;
    background: #fff;
    box-sizing: border-box;
    .vws(padding-left, 48px);
    .vws(padding-right, 48px);
    .vws(padding-top, 40px);
    .vws(padding-bottom, 40px);
    &__title {
        margin-bottom: 8px;
        font-size: 18px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #333;
        line-height: 22px;
    }
    &__message {
        font-size: 14px;
        text-align: left;
        color: #666;
        line-height: 18px;
    }
    &__icon {
        margin-top: 8px;
        .vws(height, 132px);
        img {
            width: auto;
            height: 100%;
        }
    }
    .btns {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        .btn {
            flex: 1;
            height: 44px;
            font-size: 16px;
            border-radius: 44px;
            line-height: 44px;
            &-confirm {
                font-weight: 500;
                color: #fff;
                background: #f44;
            }
            &-cancel {
                color: #222;
                background: #f5f5f5;
            }
            &:nth-child(1) {
                .vws(margin-right, 24px);
            }
        }
    }
}
</style>
